import GlobalStyles from "./constants/GlobalStyles";
import "./css/Basic.css";
import "./css/Component.css";
import RouterM from "./mobile-pages/RouterM";

// ##### 리덕스 관련 ######
import rootReducer from "./component/redux/rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

const store = configureStore({
  reducer: rootReducer,
  devTools: false,
});

function App() {
  return (
    <Provider store={store}>
      <GlobalStyles />
      {/*{isMobile ? <RouterM /> : <Router />}*/}
      <RouterM />
    </Provider>
  );
}

export default App;
