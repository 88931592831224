import React, { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { RootState } from "../component/redux/rootReducer";
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApiM";
import Sprintf from "sprintf-js";
import ProgramsItemContents from "./Compornent/ProgramsItemContents";
import AccountButton from "./Compornent/AccountButton";
import { Video } from "./Interface/Interface";
import styled, { createGlobalStyle } from "styled-components";
import VideoListModal from "./Compornent/VideoListModal";
import Calendar from "react-calendar";
import moment from "moment/moment";
import { IoIosCloseCircle } from "react-icons/io";
import NewSaveButton from "./Compornent/NewSaveButton";

const sprintf = Sprintf.sprintf;

const GlobalStyle = createGlobalStyle`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 20px;
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .react-calendar__navigation button {
    color: #09348a;
    font-weight: bold;
    background: none;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .react-calendar__navigation button:hover,
  .react-calendar__navigation button:focus {
    background-color: transparent;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button:hover,
  .react-calendar__navigation__next-button:hover,
  .react-calendar__navigation__prev-button:focus,
  .react-calendar__navigation__next-button:focus {
    background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button {
    top:40px;
    left: 73px;
    color: #999 !important;
  }

  .react-calendar__navigation__next-button {
    top: 40px;
    right: 73px;
    color: #999 !important;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    font-size: 18px;
    font-weight: bold;
    pointer-events: none;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: transparent;
    border-radius: 6px;
  }

  .react-calendar__tile--now {
    background: #ffff76;
    border-radius: 6px;
    font-weight: bold;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
    border-radius: 6px;
    font-weight: bold;
  }

  .react-calendar__tile--active {
    background: #09348a;
    border-radius: 6px;
    font-weight: bold;
    color: white;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
    color: white;
  }
`;

const DailyContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto !important;
  padding-bottom: 50px;
`;

const ProgramTiTle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: #09348a;
`;

const CalendarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const CalendarWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;

  &.visible {
    display: block;
    z-index: 999;
  }

  .react-calendar {
    border-radius: 15px;
    padding-bottom: 20px;
    position: relative;
  }

  .react-calendar__navigation {
    background: none;
    color: #000;
  }

  .react-calendar__navigation button:disabled {
    background: none;
    color: #000;
  }

  .react-calendar__tile--active {
    background-color: #09348a;
    border-radius: 10px;
  }

  .react-calendar__tile--now {
    background-color: rgba(9, 52, 138, 0.2);
    border-radius: 10px;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #09348a;
  }

  .react-calendar__month-view__weekdays {
    /* margin-top: 30px; */
    color: #09348a;
  }

  .react-calendar__month-view__days {
    margin-bottom: 50px;
  }

  .calendarSaveBtn {
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    background: #09348a;
    border: none;
    border-radius: 15px;
    color: #ffffff;
  }
`;

const SelectWrap = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: row;
`;

const Select = styled.select`
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 0.8rem;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  box-sizing: border-box;
  background: none;
  color: #09348a;
  font-size: 1.2rem;
  font-weight: 500;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:active {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }

  &:hover {
    transition: transform 0.5s linear;
    transform: rotate(45deg);
  }
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const DayProgramM: React.FC = () => {
  const history = useHistory();
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [loading, setLoading] = useState(true);
  const [arrCalData, setArrCalData] = useState<any>([]);
  const [viewWeekNo, setViewWeekNo] = useState<any>({
    weekNo: -1,
    startNo: 0,
    endNo: 0,
    maxWeekNo: 0,
  }); // #미니캘린더용 @뷰의 주차 @한주의 시작일 @한주의 끝일 @달의 최대주차
  const [selectDay, setSelectDay] = useState<any>({ day: null, fullDay: null });
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [selectedVideos, setSelectedVideos] = useState<
    {
      url: string;
      mv_nm: string;
      mv_no: string;
      mv_rep_img: string;
    }[]
  >([]);
  // const [title, setTitle] = useState();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isCalendarSelectModal, setIsCalendarSelectModal] = useState(false);
  // 캘린더 요일을 영문 표기
  const customWeekdayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const calendarWrapRef = useRef<HTMLDivElement | null>(null);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [isVideoListModalOpen, setIsVideoListModalOpen] = useState(false);

  const fetchVideos = async (selectedDay: string) => {
    const centerNo = MLoginInfo?.MLoginInfo.center_no;
    console.log("centerNo", centerNo);
    if (!centerNo) {
      console.error("센터 정보가 없습니다.");
      setLoading(false);
      return;
    }

    setLoading(true); // 로딩 시작

    try {
      const response = await ServerApi.m_app_center_pro_m({
        center_no: MLoginInfo.MLoginInfo.center_no,
        c_day: selectedDay,
      });
      // API 응답 확인 및 데이터 처리
      if (response && response.rsp_code === "100") {
        setVideos(response.array);
        console.log("response.array", response.array);

        const allVideos = response.array.flatMap((video: any) =>
          video.in_array.map((v: any) => ({
            url: v.mv_url,
            title: v.mv_nm,
            mv_no: v.mv_no,
            thumbnailUrl: v.mv_rep_img,
          }))
        );
        setSelectedVideos(allVideos);
        // setTitle(response.data.pro_m_nm);
      } else {
        console.error("데이터를 가져오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const today = new Date().toISOString().split("T")[0];
    if (isMounted) fetchVideos(today);

    return () => {
      isMounted = false;
    };
  }, []);

  const handleVideoSelect = useCallback((video: any) => {
    if (video && video.in_array) {
      const selected = video.in_array.map((v: any) => ({
        url: v.mv_url,
        title: v.mv_nm,
        mv_no: v.mv_no,
        mv_rep_img: v.mv_rep_img,
        thumbnailUrl: v.mv_rep_img,
      }));
      setSelectedVideos(selected);
      console.log("선택된 비디오의 데이터들", video);
      console.log("선택된 pro_no", video.pro_no);
    }
  }, []);

  const renderVideos = () => {
    return videos.map((video, index) => (
      <ProgramsItemContents
        key={index}
        onSelectVideo={() => handleVideoSelect(video)}
        onListClick={handleListClick}
        fetchVideos={fetchVideos}
        videoData={{
          url: video.in_array[0].mv_url,
          title: video.pro_m_nm,
          in_array: video.in_array,
          mv_nm: video.in_array[0].mv_nm,
          mv_rep_img: video.in_array[0].mv_rep_img,
          pro_no: video.pro_no,
        }}
        videos={videos}
        setVideos={setVideos}
        selectedDate={selectDay.fullDay}
        DailySetup={false}
        handle
      />
    ));
  };

  useEffect(() => {
    console.log("videos", videos);
  }, []);

  useEffect(() => {
    const today = new Date();
    initCalendar(today, true, true);
  }, []);

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const SelectCalDay = useCallback(
    async (item, idx, getViewWeelNo, isLoading) => {
      if (!item || !item.fullDay) {
        console.error('Invalid item in SelectCalDay');
        return;
      }

      const selectedDate = new Date(item.fullDay);
      if (!isValidDate(selectedDate)) {
        console.error('Invalid date in SelectCalDay');
        return;
      }

      const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
      const weekNo = calculateWeekNumber(selectedDate, firstDayOfMonth);
      const startNo = (weekNo - 1) * 7;
      const endNo = startNo + 6;

      setViewWeekNo({
        weekNo,
        startNo,
        endNo,
        maxWeekNo: getViewWeelNo.maxWeekNo,
      });
      setSelectDay({ day: item.day, fullDay: item.fullDay });
      fetchVideos(item.fullDay);
    },
    [fetchVideos]
  );

  const isValidDate = (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const getNextMonthDate = (currentDate: Date) => {
    const newDate = new Date(currentDate);
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + 1);
    return newDate;
  };

  const getPrevMonthDate = (currentDate: Date) => {
    const newDate = new Date(currentDate);
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() - 1);
    return newDate;
  };

  const calculateWeekNumber = (date: Date, firstDayOfMonth: Date) => {
    const diff = date.getTime() - firstDayOfMonth.getTime();
    const dayDiff = Math.floor(diff / (1000 * 60 * 60 * 24));
    return Math.floor((dayDiff + firstDayOfMonth.getDay()) / 7) + 1;
  };

  const initCalendar = useCallback(
    async (getDate: Date, isWeekStart: boolean, isReset: boolean) => {
      if (!isValidDate(getDate)) {
        console.error('Invalid date in initCalendar');
        return;
      }

      const calData = [];

      // 현재 달의 첫날과 마지막날 계산
      const firstDate = new Date(getDate.getFullYear(), getDate.getMonth(), 1);
      const lastDate = new Date(getDate.getFullYear(), getDate.getMonth() + 1, 0);
      const monthFirstDateDay = firstDate.getDay();

      // 이전 달의 날짜 처리
      const prevMonthLastDate = new Date(getDate.getFullYear(), getDate.getMonth(), 0);
      for (let i = 0; i < monthFirstDateDay; i++) {
        const prevDate = new Date(
          prevMonthLastDate.getFullYear(),
          prevMonthLastDate.getMonth(),
          prevMonthLastDate.getDate() - i
        );
        calData.unshift({
          date: prevDate.getDate(),
          fullDay: sprintf(
            "%04d-%02d-%02d",
            prevDate.getFullYear(),
            prevDate.getMonth() + 1,
            prevDate.getDate()
          ),
        });
      }

      // 현재 달의 날짜 처리
      for (let dayCnt = 1; dayCnt <= lastDate.getDate(); dayCnt++) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          dayCnt
        );
        const currentDate = new Date(fullDay);
        const dayOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][currentDate.getDay()];
        
        calData.push({
          day: String(dayCnt),
          fullDay: fullDay,
          dayOfWeekKor: dayOfWeek,
        });
      }

      const maxWeekNo = Math.ceil(calData.length / 7);

      // 초기화 또는 리셋 처리
      if (viewWeekNo.weekNo === -1 || isReset) {
        const fullDay = sprintf(
          "%04d-%02d-%02d",
          getDate.getFullYear(),
          getDate.getMonth() + 1,
          getDate.getDate()
        );
        SelectCalDay(
          { day: getDate.getDate(), fullDay: fullDay },
          monthFirstDateDay + getDate.getDate() - 1,
          { maxWeekNo },
          false
        );
      } else {
        const newWeekNo = isWeekStart ? 1 : maxWeekNo;
        const startNo = (newWeekNo - 1) * 7;
        const endNo = Math.min(startNo + 6, calData.length - 1);
        setViewWeekNo({ weekNo: newWeekNo, startNo, endNo, maxWeekNo });
      }

      setViewDate(getDate);
      setLoading(false);
      setArrCalData(calData);
    },
    [viewWeekNo, SelectCalDay]
  );

 

  const WeekNext = useCallback(
    async (getViewDate) => {
      if (!isValidDate(getViewDate)) {
        console.error('Invalid date in WeekNext');
        return;
      }

      const weekNo = viewWeekNo.weekNo + 1;

      if (weekNo > viewWeekNo.maxWeekNo) {
        const newDate = getNextMonthDate(getViewDate);
        setViewDate(newDate);
        initCalendar(newDate, true, true);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo, initCalendar]
  );

  const WeekPrev = useCallback(
    async (getViewDate) => {
      if (!isValidDate(getViewDate)) {
        console.error('Invalid date in WeekPrev');
        return;
      }

      const weekNo = viewWeekNo.weekNo - 1;
      if (weekNo <= 0) {
        const newDate = getPrevMonthDate(getViewDate);
        const lastDayOfPrevMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
        setViewDate(lastDayOfPrevMonth);
        initCalendar(lastDayOfPrevMonth, false, true);
      } else {
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({
          weekNo,
          startNo,
          endNo,
          maxWeekNo: viewWeekNo.maxWeekNo,
        });
      }
    },
    [viewWeekNo, initCalendar]
  );

  const renderDayItem = (idx: number, item: any) => {
    const isSelectedDay = item.fullDay === selectDay.fullDay;
    return MyUtil._isNull(item.day) ? (
      <></>
    ) : (
      <div
        key={idx}
        className={`dayProgramCalBox ${
          isSelectedDay ? "dayProgramCalBoxOn selected" : ""
        }`}
        onClick={() => {
          console.log("날짜 클릭됨:", item.fullDay);
          SelectCalDay(item, idx, viewWeekNo, loading);
        }}
      >
        <span className="calBoxWeek">{item.dayOfWeekKor}</span>
        <span className="calBoxDay">{item.day}</span>
      </div>
    );
  };

  const handleSwipe = useCallback(
    (direction: string) => {
      if (!isValidDate(activeStartDate)) {
        console.error('Invalid activeStartDate in handleSwipe');
        return;
      }

      let newDate;
      if (direction === "LEFT") {
        newDate = getNextMonthDate(activeStartDate);
      } else if (direction === "RIGHT") {
        newDate = getPrevMonthDate(activeStartDate);
      } else {
        return;
      }

      setActiveStartDate(newDate);
      initCalendar(newDate, true, false);
    },
    [activeStartDate, initCalendar]
  );

  // console.log("activeStartDate", activeStartDate);
  // console.log("viewDate", viewDate);
  // console.log("selectDay", selectDay);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    trackMouse: true,
    touchEventOptions: { passive: false },
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => WeekNext(viewDate),
    onSwipedRight: () => WeekPrev(viewDate),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const buttonToShow = ["logoOn", "play", "profile", "setup"];


  const handleListClick = () => {
    setIsVideoListModalOpen(true);
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  // 캘린더 모달을 닫는 함수
  const closeCalendarModal = () => {
    setIsCalendarSelectModal(false);
  };

  // 날짜 선택 핸들러
  const onDateChange = useCallback(
    (value: Date) => {
      const selectedDate = moment(value).format("YYYY-MM-DD");
      setSelectDay({ day: value.getDate(), fullDay: selectedDate });
      setViewDate(value);
      setActiveStartDate(value);
      initCalendar(value, true, false);
    },
    [initCalendar]
  );

  // 'SAVE' 버튼 클릭 핸들러
  const onSaveClick = () => {
    console.log("selectDay", selectDay);
    if (selectDay.fullDay) {
      const selectedDate = new Date(selectDay.fullDay);
      initCalendar(selectedDate, true, true);
      fetchVideos(selectDay.fullDay);
      setIsCalendarSelectModal(false); // 달력 모달 닫기
    }
  };

  // 연도 변경 핸들러
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setFullYear(newYear);
    setViewDate(newDate);
    setSelectDay({
      day: newDate.getDate(),
      fullDay: moment(newDate).format("YYYY-MM-DD"),
    }); // 추가
  };

  // 월 변경 핸들러
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value, 10);
    const newDate = new Date(viewDate);
    newDate.setMonth(newMonth);
    setViewDate(newDate);
    setSelectDay({
      day: newDate.getDate(),
      fullDay: moment(newDate).format("YYYY-MM-DD"),
    }); // 추가
  };

  // renderDayItem 함수 위에 추가
  useEffect(() => {
    if (
      arrCalData.length > 0 &&
      viewWeekNo.startNo !== undefined &&
      viewWeekNo.endNo !== undefined
    ) {
      const visibleDates = arrCalData.slice(
        viewWeekNo.startNo,
        viewWeekNo.endNo + 1
      );
      const startDate = visibleDates[0]?.fullDay;
      const endDate = visibleDates[visibleDates.length - 1]?.fullDay;

      console.log("현재 보이는 날짜 범위:", {
        시작일: startDate
          ? moment(startDate).format("YYYY-MM-DD (ddd)")
          : "undefined",
        종료일: endDate
          ? moment(endDate).format("YYYY-MM-DD (ddd)")
          : "undefined",
        주차: viewWeekNo.weekNo,
        전체주차: viewWeekNo.maxWeekNo,
      });
    }
  }, [arrCalData, viewWeekNo]);

  const onLogoClick = () => {
    const today = new Date();
    const todayString = moment(today).format("YYYY-MM-DD");

    if (selectDay.fullDay !== todayString) {
      setSelectDay({ day: today.getDate(), fullDay: todayString });
      setViewDate(today);
      initCalendar(today, true, true);
      fetchVideos(todayString);
    }
  };

  return (
    <>
      <div className="mobileContentsContainer">
        <div className="mobileContentsRow">
          <ProgramTiTle
            onClick={() => {
              setIsCalendarSelectModal(true);
            }}
          >
            {viewDate.getFullYear()}. {viewDate.getMonth() + 1}
          </ProgramTiTle>
        </div>

        <div className="mobileContentsRow">
          <div {...handlers} className="calendarDiv">
            {arrCalData.map(
              (item: any, idx: number) =>
                idx >= viewWeekNo.startNo &&
                idx <= viewWeekNo.endNo &&
                renderDayItem(idx, item)
            )}
          </div>
        </div>
        <DailyContent>{renderVideos()}</DailyContent>
      </div>
      <div className={"buttonContainer"}>
        {MLoginInfo && MLoginInfo.MLoginInfo && (
          <AccountButton
            buttonUse={buttonToShow}
            mod_yn={MLoginInfo.MLoginInfo.mod_yn}
            member_type={MLoginInfo.MLoginInfo.member_type}
            onLogoClick={onLogoClick}
          />
        )}
      </div>

      {isVideoListModalOpen && (
        <VideoListModal
          videos={selectedVideos}
          // title={title || ""}
          title={""}
          onClose={() => setIsVideoListModalOpen(false)}
        />
      )}

      {isCalendarSelectModal && (
        <CalendarBackdrop>
          <GlobalStyle />
          <CalendarWrap
            className={isCalendarSelectModal ? "visible" : ""}
            onClick={stopPropagation}
            {...swipeHandlers}
          >
            <CloseButton onClick={closeCalendarModal}>
              <IoIosCloseCircle />
            </CloseButton>
            <Calendar
              onChange={onDateChange}
              activeStartDate={activeStartDate}
              onActiveStartDateChange={({ activeStartDate }) => {
                if (activeStartDate) {
                  setActiveStartDate(activeStartDate);
                  initCalendar(activeStartDate, true, false);
                }
              }}
              value={viewDate}
              formatDay={(locale, date) => moment(date).format("DD")}
              minDetail="month"
              maxDetail="month"
              next2Label={null}
              prev2Label={null}
              formatShortWeekday={(locale, date) =>
                customWeekdayLabels[date.getDay()]
              }
              tileContent={({ date, view }) => {
                let html: any = [];
                return <div className="calitem">{html}</div>;
              }}
            />
            {/* <SelectWrap>
              <Select
                value={viewDate.getFullYear()}
                onChange={handleYearChange}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => moment().year() - 2 + i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
              <Select value={viewDate.getMonth()} onChange={handleMonthChange}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month - 1}>
                    {month + "월"}
                  </option>
                ))}
              </Select>
            </SelectWrap> */}
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={"ready"}
                setDnStatus={""}
                myClick={onSaveClick}
              />
            </ButtonWrap>
          </CalendarWrap>
        </CalendarBackdrop>
      )}
    </>
  );
};

export default DayProgramM;
